<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    // console.log(this.$router)
    console.log('启动');
    //判断设备类型
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      // 移动端
      console.log('mobile');
      window.location.href = 'https://www.dengshikj.com/m/#/'
    } else {
      // PC端
      console.log('pc');
    }

  }
}
</script>
<style lang="scss">
#app {
  min-width: 1200px;
}
</style>
